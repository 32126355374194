var Archive = {

	table: null,
	build_table: function(){

		window.jQuery = $;
		var DataTable = require('datatables.net')();

		this.table = $('#project-archive').DataTable({
			paging: false,
			responsive: true,
			autoWidth: false,
			"order": [[ 0, "desc" ]]
		});

		var _table = this.table;
		var default_query = 'search';
		var queries_to_test = ['query', 'q', 'search'];
		var urlParams = new URLSearchParams(window.location.search);

		var $filters = $('.filter-nav span');
		$filters.click(function(){
			var $this = $(this), query = $this.text();
			if(query === 'Clear'){
				$filters.removeClass('active');
				$this.addClass('hidden');
				_table.column(2).search('').draw();
				history.replaceState({}, "", "/archive");
			} else {
				$($filters[$filters.length - 1]).removeClass('hidden');
				$filters.removeClass('active');
				$this.addClass('active');
				_table.column(2).search(query).draw();
				history.replaceState({}, "", "/archive?"+default_query+"="+$this.data('url'));
			}
		});

		if(window.location.search != ''){
			var term = '';
			for(var i in queries_to_test){
				if(urlParams.has(queries_to_test[i])){
					term = urlParams.get(queries_to_test[i]);
				}
			}

			var $button = $('.filter-nav span[data-url="'+term+'"]');
			if($button.length){ $button.click() }
		}
	},

	better_sort_arrows: function(){
		var $up = $('<img class="asc-arrow" src="/img/icons/up_carrot.svg" />');
		var $down = $('<img class="desc-arrow" src="/img/icons/up_carrot.svg" />');
		this.table.on( 'order', function () {
			var $asc = $('.sorting_asc'), $desc = $('.sorting_desc');

			$up.remove();
			$down.remove();
			if($asc.length){
				$asc.append($up);
			}
			if($desc.length){
				$desc.append($down);
			}
		});
	},

	init: function(){
		if($('.archive-page').length){
			this.build_table();
			this.better_sort_arrows();
			// $('#project-archive').css('padding-top', $('#project-archive_filter').innerHeight());
		}
	}
};

module.exports = Archive;