'use strict';

var Global = require('./global');
var Home = require('./home');
var _projects = require('./projects');
var Project = _projects.Project;
var Projects = _projects.Projects;
var Archive = require('./archive');
var _pages = require('./pages');
var Team = _pages.Team;
var About = _pages.About;
var News = _pages.News;

const App = {
  init: function(){

		$(document).ready(function(){
			Global.init();
			Home.init();
			Project.init();
			Projects.init();
			Archive.init();
			Team.init();
			About.init();
			News.init();
		});

  }
};

module.exports = App;