var Variables = require('./variables');

var Team = {
	build_page: function(){

		function build_section(container, items){
			var total = 0, target = 0, keep_going = true;
			$(items).each(function(){
				total += $(this).innerHeight();
			});
			target = total / 2;

			$(items).each(function(){
				if(!keep_going){ return; }
				var $this = $(this), height = $this.innerHeight();
				target -= height;
				$(container).append($this);
				if(target < 0){
					keep_going = false;
				}
			});
		}

		function move_section(container, items){
			$(items).each(function(){
				$(container).append($(this));
			});
		}

		if(!Boolean($('.biography').data('single-column'))){
			build_section('.biography .left', '.team-block');
		} else {
			move_section('.biography .left', '.team-block');
		}
		if(!Boolean($('.projects').data('single-column'))){
			build_section('.projects .left', '.project-block');
		} else {
			move_section('.projects .left', '.project-block');
		}

	},

	offset_image: function(){
		var img_height = $('#navigation .logo svg').innerHeight();
		var img_padding = parseInt($('#navigation .logo').css('margin-top'));
		console.log(img_height, img_padding, $('section.introduction .right img'))
		// $('section.introduction .right img').css('transform', 'translateY('+(img_height+(img_padding*2))+'px)');
	},

	init: function(){
		if($('.team-page').length){
			this.build_page();
			this.offset_image();
		}
	}
};

var News = {
	set_filter_position: function(){
		function set_position(){
			var top = $('.site-nav').innerHeight() + parseInt($('.site-nav').css('margin-top'));
			$('.news-details').css('top', top);
		}
		set_position();
		$(window).resize(_.throttle(set_position,100));
	},
	set_layout: function(){
		function set_title_height(){
			if(window.innerWidth > Variables.tablet_breakpoint){
				$('.news-title').css('min-height', $('.site-nav .no-list-style').innerHeight());
			} else {
				$('.news-title').css('min-height', '0');
			}
		}
		set_title_height();
		$(window).resize(_.throttle(set_title_height, 100));

		var $last = $('.news-blocks .block').last();
		if($last.hasClass('odd')){ $last.next().addClass('even') }
		if($last.hasClass('even')){ $last.next().addClass('odd') }
		$last.next().addClass('block');
	},
	init: function(){
		if($('.news-page').length){
			this.set_layout();
			this.set_filter_position();
		}
	}
};

var About = {
	build_page: function(){
		function check_padding_top(){
			if(window.innerWidth < 700){
				$('section.about-studio').css({
					paddingTop: $('#navigation').innerHeight()
				});
			} else {
				$('section.about-studio').css({
					paddingTop: 0
				});
			}
		}
		check_padding_top();
		$(window).resize(_.throttle(check_padding_top, 100));
	},

	check_parallax_picture: function(){
		function check_picture(){
			var $picture = $('section.desk-picture .mobile-picture');
			if($picture.innerHeight() < window.innerHeight*0.85){
				$('section.desk-picture').addClass('mobile');
			} else {
				$('section.desk-picture').removeClass('mobile');
			}
		}
		check_picture();
		$(window).resize(_.throttle(check_picture, 100));
	},

	init: function(){
		if($('.about-page').length){
			this.build_page();
			this.check_parallax_picture();
		}
	}
};

module.exports = { Team, News, About };