var Variables = require('./variables');

var Home = {
	// generates the items from a json feed
	build_news_item: function(data){
		var photo = '';
		if(!data.photoIsHidden){
			photo = '<img src="'+data.photo+'"/>';
		}

		var categories = '';
		for(var cat in data.categories){
			categories += '<span> / </span>';
			categories += '<span> '+data.categories[cat]+' </span>';
		}
		return $('<div class="news-item">'+
			'<div class="top">'+
				photo +
			'</div>'+
			'<div class="bottom">'+
				'<div class="pt-serif-large">'+
					'<a href="'+data.url+'">'+data.title+'</a>'+
				'</div>'+
				'<div class="meta-row">'+
					'<span>'+data.date+'</span>'+
					categories+
				'</div>'+
				'<div class="description">'+
					data.description+
				'</div>'+
			'</div>'+
		'</div>');
	},

	build_news_feed: function(){
		require('imagesloaded');
		var self = this;
		var total = null;
		var horizontal = parseInt($('.news-layout .left h1').css('margin-bottom'));

		var $left = $('.news-layout .left');
		var left_height = $('.news-layout .left h1').height() + horizontal;

		var $right = $('.news-layout .right');
		var right_height = 0;

		var colors = ['grey', 'light-grey', 'blue', 'dark-blue', 'dark-grey'];

		var current_query = "/news.json";
		var current_index = 1;

		var default_query = 'search';
		var queries_to_test = ['query', 'q', 'search'];
		var urlParams = new URLSearchParams(window.location.search);

		function load_news_page(query, index){
			$('.home-news .loading-icon').removeClass('hidden');
			jQuery.ajax({
				url: query,
				type: "GET",
				data: { "page": index.toString() },
			})
			.done(function(data, textStatus, jqXHR) {
				total = data.meta.pagination.total; // load total number of items from api request

				// place new items in the same container
				for(var item in data.data){
					var $item = self.build_news_item(data.data[item]);
					$('.news-output').append($item);
				}

				$('.home-news .loading-icon').addClass('hidden');
				build_grid();
			})
			.fail(function(jqXHR, textStatus, errorThrown){
				console.log(errorThrown, jqXHR);
				//alert('Error: There was a problem fetching the news posts');
			});
		}

		function add_load_more(){
			var load_more = $('<div class="load-more pt-mono-normal">Load more news</div>');
			load_more.click(function(){
				current_index = current_index + 1;
				$(this).remove();
				load_news_page(current_query, current_index);
			});

			// figure out what to do with the load more button
			if($('.news-layout .news-item').length === total){
				load_more.remove();
			} else {
				if(window.innerWidth < Variables.phone_breakpoint){
					$right.append(load_more);
				} else {
					$left.append(load_more);
				}
			}
		}

		function build_grid(){

			// reset the height of the left / right columns if they are empty
			if($('.news-layout .news-item').length === 0){
				left_height = $('.news-layout .left h1').height() + horizontal;
				right_height = 0;
			}

			$('.news-item').imagesLoaded(function(){

				$('.news-output .news-item').each(function(i, el){
					var $this = $(this);

					$this.css('z-index', total - i); // some kind of staggered effect

					$this.removeClass(colors.join(' '));
					$this.addClass(colors[i%colors.length]);

					// if on phone, there is only one column, everything should go at the end
					if(window.innerWidth < Variables.phone_breakpoint){
						$right.append($this);
					} else { // check which column is taller and place in the other one
						if(left_height > right_height){
							$right.append($this);
							right_height += $this.find('.top').outerHeight() + $this.find('.bottom').outerHeight() + horizontal;
						} else {
							$left.append($this);
							left_height += $this.find('.top').outerHeight() + $this.find('.bottom').outerHeight() + horizontal;
						}
					}

				});

				// when the user requests more items, first all of them are removed to be re-inserted
				// this line will prevent the layout from having no height and jumping back to top of page
				$('.news-layout').css('min-height', left_height > right_height ? left_height : right_height);

				add_load_more();
			});
		}

		function clear_grid(){
			$('.news-layout .load-more').remove();
			$('.news-layout .news-item').remove();
			$(window).scrollTop($('main.home-news').offset().top);
		}

		if(window.location.search != ''){

			var term = '';
			for(var i in queries_to_test){
				if(urlParams.has(queries_to_test[i])){
					term = urlParams.get(queries_to_test[i]);
				}
			}

			if(term){
				current_index = 1;
				current_query = '/news/' + term + '.json';
			}

			$('.news-filter span:last-of-type').removeClass('hidden');
			$('.news-filter span[data-url="'+term+'"]').addClass('active');
			// no nead to load here, it already does on first load
			clear_grid();
		}

		load_news_page(current_query, current_index);

		var active_filter = null;
		$('.news-filter span').click(function(){
			active_filter = $(this).text();

			$('.news-filter span').removeClass('active');
			if(active_filter === 'Clear'){
				$(this).addClass('hidden');
				current_query = '/news.json';
				current_index = 1;

				for(var i in queries_to_test){
					if(urlParams.has(queries_to_test[i])){
						urlParams.delete(queries_to_test[i]);
					}
				}

				history.replaceState({}, "", "/"+urlParams.toString()+"#news");

			} else {
				$(this).addClass('active');
				$('.news-filter span:last-of-type').removeClass('hidden');
				current_query = '/news/' + active_filter.toLowerCase() + '.json';

				history.replaceState({}, "", "/?"+default_query+"="+active_filter.toLowerCase()+"#news");
			}

			clear_grid();
			load_news_page(current_query, current_index);
		});
	},

	sticky_filters: function(){
		var $filter = $('.news-filter'),
				push = $('.scrolling-nav').height() + parseInt($('.news-filter').css('left')),
				last = $filter.offset().top;

		$(window).scroll(_.throttle(function(){
			if(window.scrollY + push > last){
				$filter.addClass('fixed');
				$filter.css('padding-top', push);

				$('.home-nav-row').removeClass('active');
				$('.news-nav-row').addClass('active');
			} else {
				$filter.removeClass('fixed');
				$filter.css('padding-top', 0);

				$('.home-nav-row').addClass('active');
				$('.news-nav-row').removeClass('active');
			}
		}, 100));
	},

	init: function(){
		if($('.home-page').length){
			this.build_news_feed();
			this.sticky_filters();
		}
	}
};

module.exports = Home;