
var Vimeo = require('@vimeo/player');

class Video{
	constructor(el){
		this.el = $(el);
		this.src = this.el.attr('src');

		if(this.src.search('vimeo')){
			this.vimeo();
		}
	}

	vimeo(){
		this.player = new Vimeo(this.el[0]);
		console.log(this.player);

		const resize = () => {
			var width = this.el.innerWidth();
			this.el.css('height', width * this.ratio);
		};

		Promise.all([this.player.getVideoWidth(), this.player.getVideoHeight()]).then((dimensions) => {

			this.width = dimensions[0];
			this.height = dimensions[1];
			this.ratio = this.height / this.width;

			resize();
			$(window).resize(window._.throttle(()=>{ resize() }, 100));
			console.log(this.width, this.height, this.hei);
		});
		console.log(this.el);
	}
}

var Global = {


	videos: function(){

		$('iframe').each(function(){
			new Video(this);
		});
	},


	url_parser: function(){

		function parse_project(){
			var target = $('.project-title').text();

			var isFeatured = window.featured_projects.find(function(name){
				if(name === target){ return true; } else { return false; }
			});

			var categories = [];
			$('.project-categories span').each(function(){
				categories.push($(this).text());
			});

			var $categories = $('.project-categories span');

			if($categories.length){
				var $nav = $('<ol></ol>');

				$categories.each(function(){
					var $this = $(this);
					if(isFeatured){
						$nav.append('<li class="nav-row"><a href="/projects?search='+$this.data('url')+'"><span class="category-name">'+$this.text()+'</span></a></li>');
					} else {
						$nav.append('<li class="nav-row"><a href="/archive?search='+$this.data('url')+'"><span class="category-name">'+$this.text()+'</span></a></li>');
					}
				});
				$nav.append('<span class="nav-row nav-page-name">'+$('.project-title').text()+'</span>');


				if(isFeatured){
					$('.projects-nav-hook').append($nav);
					$('.projects-nav-hook').show();
				} else {
					$('.archive-nav-hook').append($nav);
					$('.archive-nav-hook').show();
				}
			}

			if(!isFeatured){
				$('.projects-nav-row').removeClass('active');
				$('.archive-nav-row').addClass('active');
			}
		}

		function parse_team(){
			$('.about-nav-hook').append('<span class="nav-row nav-page-name">'+$('.introduction h1').text()+'</span>');
			$('.about-nav-hook').show();
		}

		function parse_news(){
			var $categories = $('.news-categories span');
			if($categories.length){
				var $nav = $('<ol></ol>');
				$categories.each(function(){
					$nav.append('<li class="nav-row"><a href="/?search='+$(this).text().toLowerCase()+'#news"><span class="category-name">'+$(this).text()+'</span></a></li>');
				});
				$('.news-nav-hook').append($nav);
				$('.news-nav-hook').show();
			}
			$('.news-nav-row').addClass('active');
		}

		var segments = window.location.pathname.split('/');
		switch(segments[1]){
			case('projects'):
				if(segments[2]){
					parse_project();
				}
				break;
			case('about'):
				if(segments[2]){
					parse_team();
				}
				break;
			case('news'):
				parse_news();
				break;
		}
	},

	set_page_height: function(){
		var a = window.innerHeight - $('#footer').innerHeight();
		var pos = $('#navigation').css('position');
		if(pos === 'relative'){ a -= $('#navigation').innerHeight() }
		$('.layout').css('min-height', a);
	},

	jump_to_top: function(){
		$('.jump-to-top').click(function(){
			var time = ($(document).innerHeight() / 1200) * 0.25;
			$('html,body').animate({
				scrollTop: 0
			}, time * 1000);
		});
	},

	nav: function(){
		function map_list_style(selector, arr_values){
			var $el = $(selector);
			$el.addClass('no-list-style')
			$el.find('> li').each(function(i){
				var $count = $("<span class='roman-num'></span>");
				$count.text(arr_values[i]);
				$(this).prepend($count);
			});
		}

		var numbers = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
		var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

		map_list_style('nav.site-nav .vwa-nav', numbers);
		map_list_style('nav.site-nav .vwa-nav ol', letters);
		map_list_style('nav.scrolling-nav .vwa-nav', numbers);
		map_list_style('nav.scrolling-nav .vwa-nav ol', letters);
		map_list_style('nav.mobile-scrolling-nav .vwa-nav', numbers);
		map_list_style('nav.mobile-scrolling-nav .vwa-nav ol', letters);
	},

	scrolling_nav: function(){

		function set_closed_offset(){
			var padding = parseInt($('#navigation .site-nav').css('margin-top')) + $('#navigation .site-nav').innerHeight();
			$('.mobile-scrolling-nav').css('padding-top', padding + 2);
		}

		function set_open_offset(){
			$('.mobile-scrolling-nav').css('padding-top', $('.scrolling-nav').innerHeight() + 2);
		}

		$(window).scroll(_.throttle(function(){
			var $target = $('#navigation .site-nav ol'),
					top = $target.offset().top,
					height = $target.innerHeight(),
					trigger = top + height;

			if(trigger < window.scrollY){
				$('.scrolling-nav').addClass('active');
				set_open_offset();
			} else {
				$('.scrolling-nav').removeClass('active');
				set_closed_offset();
			}
		}, 100));

		var padding = parseInt($('#navigation .site-nav').css('margin-top')) + $('#navigation .site-nav').innerHeight();
		$('.mobile-scrolling-nav').css('padding-top', padding);
	},

	build_galleries: function(){
		var Flickity = require('flickity');

		function compute_width_fix(element){
			var $slide = $(element), $img = $slide.find('img');
			console.log($slide, $slide.innerWidth(), $slide.innerHeight(), $img.innerWidth(), $img.innerHeight());
			console.log(($slide.innerHeight() / $slide.innerWidth()), ($img.innerHeight() / $img.innerWidth()));
			console.log(($slide.innerHeight() / $slide.innerWidth()) < ($img.innerHeight() / $img.innerWidth()));
			if(($slide.innerHeight() / $slide.innerWidth()) < ($img.innerHeight() / $img.innerWidth())){
				$slide.addClass('height-fix');
			} else {
				$slide.removeClass('height-fix');
			}
		}
		function build_gallery(element){
			var flkty = new Flickity( element, {
				// options
				contain: true,
				adaptiveHeight: true,
				cellSelector: '.slide',
				pageDots: false,
				wrapAround: true,
				on: {
					ready: function() {
						var $this = $(this.element),
							$left = $this.find('.flickity-button.previous'),
							$right = $this.find('.flickity-button.next');

						$left.find('svg').remove();
						$right.find('svg').remove();
						$left.append('<img src="/img/icons/left_arrow.svg" />');
						$right.append('<img src="/img/icons/right_arrow.svg" />');

						$('.slide').each(function(){
							compute_width_fix(this);
						});
					},
					select: function(index){
						compute_width_fix(this.cells[index].element);
					},
					change: function(index){

						var $this = $(this.element), height = $this.outerHeight(), top = $this.offset().top, scroll = $('.scrolling-nav').innerHeight();
						$('html,body').scrollTop(top + (height / 2) - (window.innerHeight / 2) - (scroll/2));

						compute_width_fix(this.cells[index].element);
					},
				}
			});
			// console.log(flkty);
			setTimeout(()=>{
				compute_width_fix(flkty.cells[0].element);
			}, 1000);
		}

		$('.gallery').each(function(){
			build_gallery(this);
		});
	},

	init: function(){
		this.set_page_height();
		this.jump_to_top();
		this.scrolling_nav();
		this.url_parser();
		this.nav();
		this.videos();

		if($('.gallery').length){
			this.build_galleries();
		}

		$('.hamburger').click(function(){
			$('.hamburger').toggleClass('is-active');
			$('.mobile-scrolling-nav').toggleClass('active');
			$('#navigation.white .hamburger').toggleClass('color');
		});
	}
};

module.exports = Global;