var Project = {

	set_full_image_block_size: function(){
		function set_size(){
			var max_width = $('.full.image.block').innerWidth(),
			max_height = window.innerHeight * 0.9,
			max_ratio = max_height / max_width;

			var imagesLoaded = require('imagesloaded');

			$('.full.image.block').each(function(){
				var $this = $(this);
				var $img = $this.find('.image-block-picture');


				$img.imagesLoaded(function(){
					var img_height = $img.innerHeight(),
						img_width = $img.innerWidth(),
						img_ratio = img_height / img_width;

					if(img_ratio < max_ratio){
						$this.addClass('pin-width');
						$this.removeClass('pin-height');
					} else {
						$this.addClass('pin-height');
						$this.removeClass('pin-width');
					}
					console.log($this, $img, $img.innerWidth(), img_width, img_height, img_ratio);
				})

			});
		}

		set_size();
		$(window).resize(_.throttle(set_size, 100));
	},
	init: function(){
		if($('.project-page').length){
			this.set_full_image_block_size();
		}
	}
};

var Projects = {

	hoverEffect: function(){

		var $foo = $('.project-hover-images').remove();
		$('#viewport').append($foo); // place images in a z-index friendly place

		$('.project-list .project-list-row').each(function(){
			var $this = $(this),
					$hover_img = $this.find('.project-index-hover'),
					$project_name = $this.find('.project-name');

			if($hover_img.length){
				$hover_img.remove();
				$('.project-hover-images').append($hover_img);

				$project_name.hover(function(){
					if(!$this.hasClass('inactive')){
						$('.project-hover-images .project-index-hover').css('opacity', 0);
						$hover_img.css('opacity', 1);
					}
				}, function(){});
			}
		});
		$('.project-hover-images .project-index-hover:first-of-type').css('opacity', 1);
	},

	prune_filters: function(){
		var all_values = 'Clear';
		$('.project-list-row').each(function(){
			$(this).find('.project-categories span').each(function(){
				all_values += $(this).text();
			});
		});

		$('.filter-nav span').each(function(){
			var $this = $(this), search = all_values.search($this.text());
			if(search < 0){
				$this.hide();
			}
		});

		$('.filter-nav span.clear').hide();
	},

	filters: function(){
		function sort(query){
			$('.project-list-row').each(function(){

				var $this = $(this), is_active = false;

				$this.find('.project-categories span').each(function(){
					if($(this).text().search(query)){
						is_active = true;
					} else {
						is_active = false;
					}
				});

				if(is_active){
					$this.addClass('inactive');
				} else {
					$this.removeClass('inactive');
				}
			});
		}

		$('.filter-nav span').click(function(){
			$('.filter-nav span').removeClass('active');
			$(this).toggleClass('active');
			$('.project-list-row').removeClass('inactive');

			if($(this).text() === 'Clear'){
				$('.filter-nav span.clear').hide();
				history.replaceState({}, "", "/projects");
			} else {
				sort($(this).text());
				history.replaceState({}, "", "/projects?search="+$(this).data('url'));
				$('.filter-nav span.clear').show();
			}
		});

		var queries_to_test = ['query', 'q', 'search'];
		var urlParams = new URLSearchParams(window.location.search);

		if(window.location.search != ''){
			var term = '';
			for(var i in queries_to_test){
				if(urlParams.has(queries_to_test[i])){
					term = urlParams.get(queries_to_test[i]);
				}
			}
			var $button = $('.filter-nav span[data-url="'+term+'"]');
			console.log(term, $button);
			if($button.length){ $button.click() }
		}
	},

	set_filter_position: function(){
		function set_position(){
			var top = $('.site-nav').innerHeight() + parseInt($('.site-nav').css('margin-top'));
			$('.filter-nav').css('top', top);
		}
		set_position();
		$(window).resize(_.throttle(set_position,100));
	},

	init: function(){
		if($('.projects-page').length){
			this.hoverEffect();
			this.prune_filters();
			this.filters();
			this.set_filter_position();
		}
	}
};

module.exports = { Project, Projects };